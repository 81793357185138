@import '../../../../constants/classes.scss';
@import '../../../../constants/variables.scss';

.editBox {
    height: 100% !important;
}

.inputs {
    height: 100% !important;
    box-shadow: none !important;
    & * > * {
        opacity: 1 !important;
    }
    input[disabled] {
        background: #E8E8E8 !important;   
    }
}

.buttons {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 7.1rem !important;
    position: absolute !important;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    :global(.buttons) {
        width: 55% !important;
        height: 75% !important;
        font-size: 1.1rem !important;
        :global(.button.positive) {
            @extend .positiveBtn;
        }
        :global(.button.preview) {
            background:$side-button-background !important;
            color: $side-button-text-color !important;
        }
    }
    :global(.button.negative) {
        width: 30% !important;
        height: 75% !important;
        font-size: 1.1rem !important;
        @extend .negativeBtn;
    }
}