.uploadFileWrap {
  display: inline-block;
  position: relative;
}

.uploadFileLabel {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.listWrap {
  margin-top: 1rem !important;
}

.item {
  margin-left: 0 !important;
  i, :global(.header) {
    cursor: pointer !important;
  }
  i:global(.file) {
    color: #999 !important;
    &:hover {
      color: #4183c4 !important;
    }
  }
  :global(.header) {
    margin-right: 2rem !important;
    &:hover {
      color: #4183c4 !important;
    }
  }
}

