@import './variables.scss';

.newItemBtn {
    height: 6vh !important;
    width: 100% !important;
    text-transform: uppercase !important;
    font-size: .95rem !important;
}

.positiveBtn {
    text-transform: uppercase !important;
    background: $positive-button-background !important;
}

.negativeBtn {
    background: $negative-button-background !important;
    color: $negative-button-color !important;
    text-transform: uppercase !important;
}

.headerBar {
    padding: 1rem !important;
    border-bottom: none !important;
    background: $dominant-background !important;
    color: $header-text-color !important;
    font-weight: 900 !important; 
}

.popup {
    :global(.header) {
        @extend .headerBar;
    }
    :global(.actions) {
        background: inherit !important;
        border-top: none !important;
        :global(.button) {
            width: 7rem !important;
        }
        :global(.positive) {
            @extend .positiveBtn;
        }
        :global(.negative) {
            @extend .negativeBtn;
        }
    }
}