.tabContainer {
  height: calc(100vh - 40rem);
  overflow-y: scroll !important;

}

// @media only screen and (min-width: 992px) and (max-width: 1199px) {
//   .tabContainer {
//     height: calc(100vh - 40rem);
   
//   }

// }


@media only screen and (min-width: 1200px) {
  .tabContainer {
    height: calc(100vh - 34rem);

  }
}
