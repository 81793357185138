@import '../../constants/variables.scss';

.grid {
  height: 100% !important;
  :global(.ui.grid) {
    height: 100% !important;
    :global(.row):last-of-type {
      height: 100% !important;
    }
  }
  :global(.loader) {
    top: 40% !important;
  }
  :global(.nine.wide.column),
  :global(.ten.wide.column),
  :global(.fourteen.wide.column) {
    height: 100% !important;
  }
  :global(.top-row) {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  :global(.middle-with-top),
  :global(.top-column) {
    width: 64.285% !important;
  }
  :global(.right-with-top) {
    width: 35.715% !important;
  }
}
.left {
  background: $dominant-background !important;
  // margin-top: -1rem !important;
  margin-bottom: -1rem !important;
  padding-top: 2rem !important;
}
