@import '../../constants/variables.scss';

.menubar {
  margin: 0 !important;
  font-size: 1.15rem !important;
  border-bottom: 1px solid $menu-bar-effect-color !important;
  box-shadow: 0 1px 15px $menu-bar-effect-color !important;
 min-height: 2rem !important;
  background-color: rgb(254 171 171) !important;
  position: relative;
  z-index: 10001;
}
.menuItem {
  font-size: 1.6rem;
  padding-left: 15px !important;
  padding-right: 15px !important;
  //height: 100%;
  font-style: italic;
}
.menuItem:hover {
  // background-color: rgb(250, 250, 250) !important;
  background-color: rgb(252, 188, 188) !important;
}
.activeMenuItem {
  //font-weight: bold !important;
  font-size: 1.2rem;
  color: $menu-bar-text-color !important;
  // background-color: rgb(240, 240, 240) !important;
}
