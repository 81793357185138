.selected {
  position: relative;
  z-index: 1;
}

.border {
  border: 1px dotted #a9a9a9;
}

.horizontalLine {
  position: absolute;
  left: 0;
}
