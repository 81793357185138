@import '../../../../constants/variables.scss';

.view {
    :global(.grid) {
        :global(.column):first-of-type {
            padding-left: 2rem !important;
        }
        :global(.column):last-of-type {
            padding-right: 2rem !important;
        }
    }
    :global(.segment):first-of-type {
        border-top: 1px solid $border-color !important;
    }
    :global(.segment.disabled) {
        border-top: 1px solid $border-color !important;
        border-bottom: 0 !important;
    }
}