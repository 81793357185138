
.cardHeader{
  background-color: #d4d4d5;
  padding-top: 1rem;
  padding-bottom: 1rem;
 
}
.cardDescription{
  min-height: 3rem;
 display: flex;
 align-items: center;
justify-content: center;
text-align: center ;
}