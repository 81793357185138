@import '../../../constants/classes.scss';
@import '../../../constants/variables.scss';

.simple {
    z-index: 999 !important;
    :global(.header) {
        @extend .headerBar;
    }
    :global(.actions) {
        background: inherit !important;
        border-top: none !important;
        :global(.button) {
            background: $dominant-background !important;
            color: $header-text-color !important;
            font-weight: 900 !important;
        }
    }
}

.content {
    padding: 2rem !important;
}

.popup {
    z-index: 999 !important;
}