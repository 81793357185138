.group {
  max-height: calc(100vh - 14.5em) !important;
  box-shadow: none !important;
  overflow-y: scroll !important;
  :global(.grid) {
    cursor: pointer !important;
    font-size: 1.1rem !important;
  }
  :global(.segment):hover {
    background: rgba(0, 0, 0, 0.03);
  }
  :global(.ui.segment.disabled) {
    color: #282828 !important;
  }
}
