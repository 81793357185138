.uploadFileWrap {
  display: inline-block;
  position: relative;
  margin-bottom: 1rem;
}

.uploadFileLabel {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.center{
 margin: auto;
 width:22rem
}