@import '../../../../constants/variables.scss';

.topBar {
    :global(.column):first-of-type {
        font-weight: 900;
        font-size: 1.2rem !important;
        color: $header-text-color !important;
    }
    :global(.column):last-of-type {
        padding-bottom: .5em !important;
        i {
            cursor: pointer !important;
            color: $header-text-color !important;
            font-size: 1.35rem !important;
            margin-right: .8rem !important;
        }
        i:global(.toggle.icon) {
            font-size: 1.75rem !important;
            vertical-align: text-top !important;
        }
        i:last-of-type {
            margin-right: 0 !important;
        }
    }
}