.grid {
  height: 100%;
  margin: 0 !important

}

.column {
  padding: 0 !important;
  width: 70%  !important;
  height: calc(100vh - 6.2rem)  !important;
  overflow-y: scroll  !important
}

.tab {
  width:30% !important;
  padding-right: 0  !important
}

.segment {
  max-height: 20rem  !important;
   overflow-y: auto  !important
}

@media only screen and (min-width: 1200px) {
  .segment {
    max-height: 26rem;
    
  }
}

