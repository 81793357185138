body {
  font-family: 'Lato', sans-serif;
  overflow: hidden;
}

#root {
  height: 100vh;
}

#root > div:first-of-type {
  height: 100vh;
}

main {
  height: 91vh;
}

.grid-container-main {
  display: grid;
  grid-template-columns: 3fr;
  grid-template-rows: 0.4fr 3.6fr;
  gap: 1px 1px;
  grid-template-areas: 'top-main' 'mid-main';
}

.top-main {
  grid-area: top-main;
}

.mid-main {
  grid-area: mid-main;
}

.grid-container {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 0.5fr 0.5fr;
  grid-template-rows: 0.5fr 2fr 5fr;
  gap: 1px 1px;
  grid-template-areas:
    'left-panel top-panel-middle top-panel-middle top-panel-right'
    'left-panel middle-panel     middle-panel     right-panel'
    'left-panel middle-panel     middle-panel     right-panel';
}

.left-panel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-area: left-panel;
  grid-template-areas:
    'left-panel-top left-panel-top left-panel-top'
    'left-panel-bot left-panel-bot left-panel-bot';
  background-color: #ccffcc;
  width: 99%;
}

.left-panel-top {
  grid-area: left-panel-top;
  background-color: blueviolet;
}

.left-panel-bot {
  grid-area: left-panel-bot;
}

.top-panel-middle {
  grid-area: top-panel-middle;
  background-color: #ccffcc;
  width: 100%;
}

.top-panel-right {
  grid-area: top-panel-right;
  background-color: #ccffcc;
  width: 100%;
}

.right-panel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px 1px;
  grid-area: right-panel;
  grid-template-areas:
    'right-panel-top right-panel-top right-panel-top'
    'right-panel-bot right-panel-bot right-panel-bot';
  background-color: #ccffcc;
}

.right-panel-top {
  grid-area: right-panel-top;
}

.right-panel-bot {
  grid-area: right-panel-bot;
}

.middle-panel {
  grid-area: middle-panel;
  /*background-color: aqua;*/
  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
