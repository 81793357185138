.topButton {
  margin-top: -4.3rem;
  margin-bottom: 1.1rem;
  position: relative;
  z-index: 5;
}

.preview {
  background: #1aa0e0 !important;
  color: #fff !important;
}

.back {
  margin-right: 1rem !important;
}
