.column {
    font-weight: 900 !important;
    font-size: 1rem !important;
    cursor: pointer !important;
    user-select: none;
}

.disabled {
    opacity: .5 !important;
    pointer-events: none !important;
}

.table {
    display: flex;
    flex-direction: column;
    height: 100%;
}