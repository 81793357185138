.listContainer {
  overflow-x: scroll !important;
  border: none !important;
}
.inputComponent {
  margin: 0 !important;
}
.itemHeader {
  margin-bottom: 0.5em !important;
}
.listItem {
  padding: 0em 1.33333333em 0em 1.33333333em !important;
}
.itemMenu {
  margin-top: 0 !important;
}
.plusIcon {
  margin-left: 15px !important;
  color: grey !important;
  font-size: 1.3em !important;
}
.plusIcon:hover {
  cursor: pointer !important;
  color: rgb(99, 98, 98) !important;
}
.minusIcon {
  margin: 0 !important;
  margin-left: 10px !important;
  top: 50%;
  transform: translateY(250%);
  color: grey !important;
  font-size: 1em !important;
}
.minusIcon:hover {
  cursor: pointer !important;
  color: rgb(99, 98, 98) !important;
}
