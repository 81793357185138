.componentSelected {
  background-color: rgb(231, 228, 228);
  /* border-color: #2680eb;
  border-width: 1px;
  border-style: dashed; */
  /* box-shadow: 5px 10px 5px #888888; */
  -webkit-box-shadow: 0 0 10px #888888;
  box-shadow: 0 0 10px #888888;
  outline: 0px solid transparent;
  background-image: linear-gradient(
      to bottom,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 100%
    ),
    linear-gradient(to bottom, rgb(241, 241, 241) 0%, rgb(241, 241, 241) 100%);
  background-clip: content-box, padding-box;
}

.buttonSelected {
  opacity: 0.5;
}

.indicatorDiv, .pageIndicator{
  display: flex;
  position: absolute;
  height: 30px;
  margin-top: -29px;
  font-size: 12px;
  color: #FFF;
  line-height: 15px;
  background-color: #2680eb;
  padding: 0.5rem;
}

.pageIndicator:before {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  right: 18px;
  border: 8px solid transparent;
  border-right-color: #2680eb;
}

.nameDiv{
  margin-right: 0.5rem;
}

.cursorMove{
  cursor: move;
  margin-left: 0.5rem;
}