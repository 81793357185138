.top {
  z-index: 4;
  position: relative;
}

.title {
  width: 100%;
}
.contentModal {
  height: calc(100vh - 5em) !important;
}
.docPreviewContainer {
  width: 100%;
}
