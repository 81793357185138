@import '../../constants/classes.scss';

.newTemplateBtn {
  @extend .newItemBtn;
  margin-top: 1rem !important;
}
.topBar {
  margin-top: 1rem !important;
  display: flex;
  justify-content: flex-end;
}
